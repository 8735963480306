import React from "react"
import { IoFastFoodOutline, IoPricetagOutline } from "react-icons/io5"
import CommonProduct from "../../components/common/CommonProduct"
import Layout from "../../components/layout"
import image from "../../../static/Client/hariharalogo.jpg"
import { AiOutlineThunderbolt } from "react-icons/ai"
const HariHaraFood = () => {
  const data = {
    name: "Hari Hara Foods",
    description:
      "Hari Hara Foods is a reliable food delivery app that brings delicious meals from your favorite restaurants straight to your doorstep. Enjoy fast delivery, diverse cuisines, and great offers at your fingertips.",
    image: image,
    features: [
      {
        id: 1,
        icon: <IoFastFoodOutline />,
        title: "Wide Variety of Cuisines",
        description:
          "Dive into a culinary adventure with Hari Hara Foods' diverse menu that caters to every craving and mood. From authentic Indian dishes rich with spices and tradition to mouth-watering Chinese delicacies, flavorful Italian pastas and pizzas, and wholesome Continental meals, we bring flavors from around the world straight to your plate. ",
      },
      {
        id: 2,
        icon: <AiOutlineThunderbolt />,
        title: "Fast Delivery",
        description:
          "At Hari Hara Foods, we understand that when you're hungry, every second counts. Our advanced logistics system ensures that your order is prepared, packed, and delivered as quickly as possible.",
      },
      {
        id: 3,
        icon: <IoPricetagOutline />,
        title: "Affordable Prices",
        description:
          "We believe that everyone deserves to enjoy delicious meals without worrying about the cost. Hari Hara Foods offers competitive prices that make eating out affordable for all. Take advantage of our daily deals, special combo offers, and seasonal discounts that help you enjoy your favorite meals without stretching your budget.",
      },
    ],
  }

  return (
    <Layout>
      <CommonProduct
        name={data.name}
        description={data.description}
        image={data.image}
        features={data.features}
      />
    </Layout>
  )
}

export default HariHaraFood
